import React, { Component, useReducer } from 'react';
import {Collapse,FooterToggler,FooterBrand,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import Instagram from '../images/instagram.png'
import Facebook from '../images/facebook.png'
import Twitter from '../images/twitter.png'
import { AiOutlineWhatsApp } from 'react-icons/ai';

class Footer extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {

    };

  }

  componentDidMount() {
    
  }




  render() {
    return (
      <div  style={{minHeight: '10px', backgroundColor: '#FFFFFF'}}>
        <Container>
          <Row style={{textAlign: 'left', color: ''}}>
            <Col sm='4'>
              <br/><br/>
              <h6 style={{fontWeight: 'bold', color: '#000000'}}>Get In Touch</h6>
              <br/>
              admin@eleanoross.com
              <br/><br/>
              <AiOutlineWhatsApp size='30px' style={{color: 'gold'}} /> +44 7311 273 351
              <br/>
              <a href='https://wa.me/447311273351' style={{color: '#021040', fontSize: '13px'}}>
                Click here to send us a WhatsApp message.
              </a>
            </Col>
            <Col sm='4'>
              <br/><br/>
              <h6 style={{fontWeight: 'bold', color: '#000000'}}>Quick Links</h6>
              <br/>
              <a href='#home' style={{color: 'inherit'}}>
                Home
              </a>
              <br/><br/>
              <a href='#services' style={{color: 'inherit'}}>
                Services
              </a>
              <br/><br/>
              <a href='#about' style={{color: 'inherit'}}>
                About
              </a>
              <br/><br/>
              <a href='#testimonials' style={{color: 'inherit'}}>
                Testimonials
              </a>
            </Col>
            <Col sm='4'>
              <br/><br/>
              <h6 style={{fontWeight: 'bold', color: '#000000'}}>Social Media</h6>
              <br/>
              <a href='https://www.linkedin.com/company/eleanor-ross-ltd' target='_blank' style={{color: 'inherit'}}>
                LinkedIn
              </a>
              <br/><br/>
              <a href='https://www.facebook.com/eleanorrossltd' target='_blank' style={{color: 'inherit'}}>
                Facebook
              </a>
              {/* <br/><br/>
              <a href='https://www.instagram.com/' target='_blank' style={{color: 'inherit'}}>
                Instagram
              </a> */}
            </Col>
          </Row>
          <br/>
          <Row style={{color: '#565555', marginTop: '20px', textAlign: 'left', fontSize: '14px'}}>
            <Col sm='1'>

            </Col>
            <Col>
              &copy; 2021 ELEANOR ROSS. Eleanor Ross is registered in England No 13598299
            </Col>
          </Row>
          <br/><br/>
        </Container>
      </div>
    );
  }

};

export default withCookies(Footer);