import React, { Component } from 'react';
import './App.css';
import {Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import PageNotFound from './components/page_not_found'
import NavBar from './components/navbar';
import Footer from './components/footer'
import Home from './components/home'
import Loader from 'react-loader-spinner'

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {

    };
  }
  componentDidMount() {

  }

  render() {
    return (
      <div className="App" style={{}}>
        <NavBar/>
        <BrowserRouter>
            <Switch>
                <Route path='/' exact={true} component={Home}/>
                <Route path="*" component={PageNotFound} />
            </Switch>
        </BrowserRouter>  
        <Footer/>  
      </div>
    );
  }
}

export default withCookies(App);
