import React, { Component, useReducer } from 'react';
import {Collapse,Navbar,NavbarToggler,NavbarBrand,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { AiOutlineUser } from "react-icons/ai";
import {Backend_Url} from './backend_url'
import Logo from '../images/logos/logo.png'
import { IoMdNotificationsOutline } from 'react-icons/io';
import { BiBuildingHouse } from 'react-icons/bi'
import Notification from '../sounds/notification.wav'

class NavBar extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) { 
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggle = () => {
      this.setState({
          isOpen: !this.state.isOpen
      });
    };
    
    this.myRef = React.createRef();

    this.scroll = (ref) => {
      console.log('ref', ref)
      console.log('ref current', ref.current)
      // ref.current.scrollIntoView({behaviour: 'smooth'})
    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <Navbar light expand="md" sticky='top' style={{backgroundColor: '#FFFFFF'}}>
        <NavbarBrand href="/" style={{marginLeft: '-16px', marginRight: '-16px', marginTop: '-110px', marginBottom: '0px', height: '310px', backgroundColor: '', color: '#FFFFFF', minWidth: '42%'}}>
          {/* <Row style={{marginRight: '0px', margin: '20px', marginTop: '95px', maxWidth: '38%'}}>
              
          </Row> */}
          <img src={Logo} style={{width: '220px', marginLeft: '0%', marginTop: '100px'}} />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} style={{backgroundColor: '#ffffff'}}/>
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
                <NavLink></NavLink>
            </NavItem>                
            <NavItem>
                <NavLink>
                <Button href='#home' style={{backgroundColor: 'inherit', border: 'none', color: '#426a7f', marginTop: '-50px', outline: 'none', boxShadow: 'none'}}>
                      HOME
                </Button>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink></NavLink>
            </NavItem>
            <NavItem>
                <NavLink>
                <Button href='#services' style={{backgroundColor: 'inherit', border: 'none', color: '#426a7f', marginTop: '-50px', outline: 'none', boxShadow: 'none'}}>
                    SERVICES
                </Button>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink></NavLink>
            </NavItem>
            <NavItem>
                <NavLink>
                <Button href='#about' style={{backgroundColor: 'inherit', border: 'none', color: '#426a7f', marginTop: '-50px', outline: 'none', boxShadow: 'none'}}>
                    ABOUT
                </Button>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink></NavLink>
            </NavItem>
            <NavItem>
                <NavLink>
                <Button href='#testimonials' style={{backgroundColor: 'inherit', border: 'none', color: '#426a7f', marginTop: '-50px', outline: 'none', boxShadow: 'none'}}>
                    TESTIMONIALS
                </Button>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink></NavLink>
            </NavItem>
            <NavItem>
                <NavLink>
                {/* <Button href='#contact' style={{backgroundColor: 'inherit', border: 'none', color: '#426a7f', marginTop: '-50px', outline: 'none', boxShadow: 'none'}}>
                  CONTACT
                </Button> */}
                </NavLink>
            </NavItem>
            <NavItem style={{width: '4vw'}}>
                <NavLink>

                </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }

};

export default withCookies(NavBar);