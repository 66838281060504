import React, { Component, useReducer } from 'react';
import {Collapse,Nav,NavItem,NavLink,UncontrolledDropdown,
 Button, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Container, Label, InputGroup} from "reactstrap";
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {Helmet} from 'react-helmet'
import {Backend_Url} from './backend_url'
import LondonImage from '../images/london.jpg'
import Image1 from '../images/image_1.jpg'
import Image2 from '../images/image_2.jpg'
import LondonGif from '../images/london_2.jpg'
import * as emailjs from 'emailjs-com'


class Home extends Component{
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) { 
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      on_mobile: true,
      backgroundImage: '',
      backgroundPositionY: '',
      backgroundPositionX: ''
    };

    this.HandleChange = (e) =>{
      this.setState({[e.target.name]: e.target.value});
    };

    this.SendMessage = (e) => {
      e.preventDefault()
      // let message = `
      //   <div>
      //     <h5>Incoming Message from Eleanor Ross Contact Form</h5>
      //     <br/>
      //     Name: ${this.state.name}<br/>
      //     Email: ${this.state.email}<br/>
      //     Subject: ${this.state.subject}<br/>
      //     Message: ${this.state.message}<br/>
      //   </div>
      // `

      var templateParams = {
        from_name: this.state.name,
        to_name: 'Eleanor Ross',
        reply_to: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
      }

        try{
          emailjs.send(
            "service_4wxz98e",
            "template_1xcw4af",
            templateParams
          )
          alert('Message sent successfully.')
          this.setState({
            name: '', email: '', subject: '', message: ''
          })
        }catch(err){
          alert('Something went wrong, please try again.')
        }
    }

    this.Space = () => {
      if (this.state.on_mobile == true){
        return<div>

        </div>
      }else{
        return<div style={{minHeight: '600px'}}>

        </div>
      }
    }

    this.ServicesLabel = () => {
      if (this.state.on_mobile == true){
        return<>
          <h1 style={{color: '#021040', marginTop: '200px', fontWeight: 'bold', textAlign: 'center'}}>
            SERVICES
          </h1>
          <div style={{backgroundColor: '#002E5D', borderRadius: '20px', width: '120px', height: '6px', marginTop: '15px', marginLeft: 'auto', marginRight: 'auto'}}>
            
          </div>
        </>
      }else{
        return<>
          <h1 style={{color: '#021040', marginTop: '200px', fontWeight: 'bold', textAlign: 'right', marginRight: '40px'}}>
            SERVICES
          </h1>
          <div style={{backgroundColor: '#002E5D', borderRadius: '20px', width: '120px', height: '6px', position: 'absolute', right: '18%', marginTop: '15px'}}>
            
          </div>
        </>
      }
    }
  }

  componentDidMount() {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.setState({
        on_mobile: true,
        backgroundImage: "url(" + LondonImage + ")",
        backgroundPositionY: '30%',
        backgroundPositionX: '-150px'
      })
    }else{
      this.setState({
        on_mobile: false,
        backgroundImage: "url(" + LondonGif + ")",
        backgroundPositionY: '-610px',
        backgroundPositionX: '0px'
      })
    }
  }




  render() {
    return (
      <div style={{minHeight: '600px', backgroundImage: this.state.backgroundImage, backgroundPositionY: this.state.backgroundPositionY, backgroundPositionX: this.state.backgroundPositionX, WebkitBackgroundSize: 'cover', MozBackgroundSize: 'cover', OBackgroundSize: 'cover', backgroundSize: 'cover', background: 'fixed'}}>
          <Helmet>
            <title>Eleanor Ross</title>
            {/* <meta name="description" content="" /> */}
          </Helmet>
          <div id='home' style={{minHeight: '150px', marginTop: '-150px', visibility: 'hidden'}}>

          </div>
          <div>
            <Row style={{marginRight: '0px'}}>
              <Col sm='5' style={{alignItems: 'center', justifyContent: 'center', alignContent: 'center'}}>
                <Row style={{minHeight: '600px', backgroundColor: '#021040', opacity: 0.7, textAlign: 'center'}}>

                </Row>
                <div style={{position: 'absolute', top: '300px', textAlign: 'center', left: '13%', right: '13%'}}>
                  <h4 style={{color: '#FFFFFF', textAlign: 'center'}}>
                    WE EXIST TO ENSURE YOUR RELOCATION<br/> TO THE UNITED KINGDOM <br/>IS ENJOYABLE AND LESS STRESSFUL.
                  </h4>
                  <Button href='#services' style={{transform: 'rotate(180deg)', fontSize: '100px', marginTop: '-10px', backgroundColor: 'inherit', color: '#ffffff', border: 'none', outline: 'none', boxShadow: 'none'}}>
                    ^
                  </Button>
                </div>
              </Col>
              <Col style={{minHeight: '600px'}}>

              </Col>
            </Row>
          </div>
          <div id='services' style={{minHeight: '150px', marginTop: '-150px', visibility: 'hidden'}}>

          </div>
          <div style={{backgroundColor: '#ffffff'}}>
            <Row style={{marginRight: '0px'}}>
              <Col sm='5' style={{backgroundColor: '#C0D2E5', minHeight: '600px'}}>
                <this.ServicesLabel/>
              </Col>
              <Col style={{backgroundColor: '#FFFFFF', minHeight: '600px'}}>
                <Row style={{marginRight: '0px'}}>
                  <Col sm='6' style={{marginTop: '10px'}}>
                    <Container style={{backgroundColor: '#F7F7F7'}}>
                      <img src={Image1} style={{width: '100%'}} />
                      <br/>
                      <h4 style={{color: '#3D5975', fontWeight: 'bold', marginTop: '15px'}}>
                        Accommodation Search
                      </h4>
                      <p style={{textAlign: 'left', marginTop: '15px', color: '#676767'}}>
                        Most companies in the UK no longer offer any accommodation to prospective employees therefore 
                        we assist with the house or room search. We also understand the financial strain which can be brought 
                        about by relocation thus we provide rental support as rent can constitute a major portion of your 
                        relocation costs.
                      </p>
                      <br/><br/>
                    </Container>
                  </Col>
                  <Col sm='6' style={{marginTop: '10px'}}>
                    <Container style={{backgroundColor: '#F7F7F7'}}>
                      <img src={Image2} style={{width: '100%'}} />
                      <br/>
                      <h4 style={{color: '#3D5975', fontWeight: 'bold', marginTop: '15px'}}>
                        Car Booking
                      </h4>
                      <p style={{textAlign: 'left', marginTop: '15px', color: '#676767'}}>
                        As a newbie in the country you might not be aware of how to get on, thus we will do 
                        the car booking on your behalf from the airport to your said destination.
                      </p>
                      <br/><br/>
                    </Container>
                  </Col>
                  <Col sm='6' style={{marginTop: '10px'}}>
                    <Container style={{backgroundColor: '#F7F7F7', textAlign: 'left', color: '#676767'}}>
                      <br/>
                      <h5 style={{color: '#3D5975', fontWeight: 'bold', marginTop: '15px'}}>
                        Specific services include:
                      </h5>
                      <br/>
                      <li>
                          <span style={{textAlign: 'left', marginTop: '15px', color: '#676767'}}>
                            Accommodation search and viewings
                          </span>
                      </li>
                      <br/>
                      <li>
                          <span style={{textAlign: 'left', marginTop: '15px', color: '#676767'}}>
                            Rental Deposit Support
                          </span>
                      </li>
                      <br/>
                      <li>
                          <span style={{textAlign: 'left', marginTop: '15px', color: '#676767'}}>
                            Car booking
                          </span>
                      </li>
                      <br/>
                      <li>
                          <span style={{textAlign: 'left', marginTop: '15px', color: '#676767'}}>
                            Tailored City Travel Guides including Visa collections and site visits
                          </span>
                      </li>
                      <br/>
                      <li>
                          <span style={{textAlign: 'left', marginTop: '15px', color: '#676767'}}>
                            IELTS (English test) preparatory training
                          </span>
                      </li>
                      <br/>
                      <li>
                          <span style={{textAlign: 'left', marginTop: '15px', color: '#676767'}}>
                            Other Ancillary Services
                          </span>
                      </li>
                      <br/><br/>
                    </Container>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br/><br/>
          </div>
          <div id='about' style={{minHeight: '150px', marginTop: '-150px', visibility: 'hidden'}}>

          </div>
          <div>
            <Row style={{marginRight: '0px', minHeight: '600px', backgroundColor: '#021040', color: '#FFFFFF'}}>
              <Container>
                <h1 style={{marginTop: '90px', fontWeight: 'bold'}}>
                  OUR VISION
                </h1>
                <div style={{backgroundColor: '#ffffff', borderRadius: '20px', width: '120px', height: '6px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px'}}>
                  
                </div>
                <br/><br/>
                <p style={{textAlign: ''}}>
                  Eleanor Ross is a company based in the United Kingdom. We understand how daunting it can be 
                  to relocate without accommodation and an idea on how to navigate the country thus we exist 
                  to make the relocation enjoyable and less stressful by assisting you in house search, car 
                  booking from the airport to your hotel or new home.
                </p>
                <p style={{textAlign: ''}}>
                  We are driven by a commitment to provide high quality services by tailoring them to each 
                  individual client.
                </p>
                <h1 style={{marginTop: '90px', fontWeight: 'bold'}}>
                  VALUES
                </h1>
                <div style={{backgroundColor: '#ffffff', borderRadius: '20px', width: '120px', height: '6px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px'}}>
                  
                </div>
                <br/><br/>
                <p style={{textAlign: '', marginBottom: '90px'}}>
                  Our values are at the core of our purpose and guide the individuals who work at Eleanor Ross Ltd.
                  <br/><br/>
                  <span style={{color: 'gold'}}>People oriented</span>
                  <br/>
                  We focus on the needs of both our employees and clients to ensure relationship building and keeping them 
                  happy.
                  <br/><br/>
                  <span style={{color: 'gold'}}>Professionalism</span>
                  <br/>
                  We believe in being responsible and doing the right thing all the time.
                  {/* <li> People oriented
                    <ul>
                      We focus on the needs of both our employees and clients to ensure relationship building and keeping them 
                      happy.
                    </ul>
                  </li> */}
                  {/* <li> Professionalism
                    <ul>
                      We believe in being responsible and doing the right thing all the time.
                    </ul>
                  </li> */}
                </p>
              </Container>
            </Row>
          </div>
          <div id='testimonials' style={{minHeight: '150px', marginTop: '-150px', visibility: 'hidden'}}>

          </div>
          <div>
            <Row style={{marginRight: '0px', minHeight: '600px', backgroundColor: '#FFFFFF', color: ''}}>
              <Container>
                <h1 style={{marginTop: '90px', fontWeight: 'bold'}}>
                  Testimonials
                </h1>
                <div style={{backgroundColor: 'gold', borderRadius: '20px', width: '120px', height: '6px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px'}}>
                  
                </div>
                <br/><br/>
                <h4>
                  We appreciate our clients’ feedbacks!
                </h4>
                <br/>
                <h6>
                  Here’s what some of our clients say about our work.
                </h6>
                <br/><br/>
                <div class="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="617c45895e43cf3543ffde95" data-style-height="24px" data-style-width="100%" data-theme="light">
                  <a href="https://uk.trustpilot.com/review/eleanoross.com" target="_blank" rel="noopener">Trustpilot</a>
                </div>
                <br/><br/>
                <div class="trustpilot-widget" data-locale="en-GB" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="617c45895e43cf3543ffde95" data-style-height="52px" data-style-width="100%">
                  <a href="https://uk.trustpilot.com/review/eleanoross.com" target="_blank" rel="noopener">Trustpilot</a>
                </div>
              </Container>
            </Row>
          </div>
          <this.Space/>
          {/* <div id='contact' style={{minHeight: '150px', marginTop: '-150px', visibility: 'hidden'}}>

          </div>
          <div style={{minHeight: '600px', backgroundColor: '#FFFFFF'}}>
            <Container>
                <br/>
                <h1 style={{marginTop: '55px', fontWeight: 'bold', color: '#C6C6C6'}}>
                  CONTACT
                </h1>
                <div style={{backgroundColor: '#002E5D', borderRadius: '20px', width: '120px', height: '6px', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px'}}>
                  
                </div>
                <br/><br/>
                <h5 style={{textAlign: 'left', color: '#1E2D3B', fontWeight: ''}}>
                  Enquiries
                </h5>
                <br/>
                <Row style={{textAlign: 'left', marginBottom: '5px'}}>
                  <Col>
                    <Input type="text" name="name" style={{backgroundColor: '#F7F7F7', border: 'none'}} placeholder="Name *"
                    value={this.state.name} onChange={this.HandleChange} />
                  </Col>
                </Row>
                <Row style={{textAlign: 'left', marginBottom: '5px'}}>
                  <Col>
                    <Input type="text" name="email" style={{backgroundColor: '#F7F7F7', border: 'none'}} placeholder="Email *"
                    value={this.state.email} onChange={this.HandleChange} />
                  </Col>
                </Row>
                <Row style={{textAlign: 'left', marginBottom: '5px'}}>
                  <Col>
                    <Input type="text" name="subject" style={{backgroundColor: '#F7F7F7', border: 'none'}} placeholder="Subject *"
                    value={this.state.subject} onChange={this.HandleChange} />
                  </Col>
                </Row>
                <Row style={{textAlign: 'left', marginBottom: '5px'}}>
                  <Col>
                    <Input type="textarea" rows='5' name="message" style={{backgroundColor: '#F7F7F7', border: 'none'}} placeholder="Message *"
                    value={this.state.message} onChange={this.HandleChange} />
                  </Col>
                </Row>
                <br/>
                <div style={{textAlign: 'right'}}>
                  <Button onClick={this.SendMessage} style={{backgroundColor: '#1E2D3B', color: '#ffffff', width: '100px', border: 'none', borderRadius: '20px'}}>
                    Send
                  </Button>
                </div>
                <div style={{textAlign: 'left', color: '#565555'}}>
                  Or 
                  <br/>
                  Give us a call on +44 7311 273 351
                </div>
                <br/><br/><br/>
            </Container>
          </div> */}
      </div>
    );
  }

};

export default withCookies(Home);